// import homeimage from "../assets/bg-header.jpg"
// import Header from "../components/Header";
import classes from "./About.module.css"
import logo1 from "../assets/bryon.jpg";
import LineBreak from "../UI/LineBreak";

const p1 = "Bryon has a Master’s Degree in Marriage and Family Therapy that lead him to working with students in the school setting. While doing individual counseling, Bryon met a student that engaged in aggression when he was asked to read out loud in a classroom. Through research and consulting, Bryon met with a school psychologist that was able to help the student who had signs of dyslexia. Shortly after helping the student, Bryon went back to school to get his second Master’s Degree in School Psychology.  In 2010, he completed his Master’s Degree in Educational Psychology and began working as a School Psychologist.  His desire to enhance his understanding of how knowledge, memory, and attention works along with how the skills may have a neurological break-down, lead him to pursue post-graduate studies School Neuropsychology.  In 2012, after successful completion of a reputable competency-based program (www.schoolneuropsych.com), he became certified through the American Board of School Neuropsychology.  The training he obtained has added to a strong desire for the profession, and for in-depth ways for assessing, diagnosing, and remediating individuals with learning needs"

const p2 = "As Bryon began putting into practice his advanced training, it became apparent how comprehensive assessments were beneficial to truly understand a person's academic needs, and for the development of “targeted” recommendations. After several more years of service in the public-school setting, Bryon sought to further utilize his expertise by going into private practice."

const p3 = "In 2022, after completing over 1,000 assessments, Bryon became a Licensed Educational Psychologist through the California Board of Behavioral Sciences. This has given him the opportunity to help clients in private practice and educate alternative methods to learning. In this setting, he has been able to be more thorough in assessments than is often done in the schools.  Bryon’s passion for learning and research with science related to his profession has helped him better understand the needs of individual clients. It has been a goal for Bryon to specialize in education and assessments while create treatments for each person’s unique  learning profile."

const About = () => {
  return (

    <>
      {/* <Header className={classes.header_main} imagebg={homeimage}/> */}


      <div className={classes.about_container}>
        <h1>Bryon Vignerie, Licensed Educational Psychologist</h1>
        <div className={classes.image_container}>
          <img src={logo1} alt="aboutme_logo"/>
          <p>{p1}</p>
          <LineBreak size={"small"}/>
          <p>{p2}</p>
          <LineBreak size={"small"}/>
          <p>{p3}</p>
        </div>
      </div>
      
    </>
  )
}

export default About;