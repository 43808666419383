import classes from './InfoCard.module.css'
import React from 'react'

const InfoCard = (props) => {

  const styl = classes.info_main + ' ' + props.className 


  return (
    <div className={styl}>
      <img className={classes.info_main_img} src={props.logo} alt="logo"/>
      <h2>{props.title}</h2>
      <p className={classes.info_main_desc}>{props.description}</p>


    </div>
  )
}

export default InfoCard