import React from 'react'
import classes from './TextArea.module.css'
const TextArea = (props) => {

  let title = props.title;
  if(props.required){
    title = (<span>
              {props.title}
              <span style={{color: "red"}}>*</span>
            </span>)
  }

  return (
    <section className={classes.textarea_section}>
      <label htmlFor={props.inputId}>{title}</label>
      <textarea 
        name={props.name} 
        required={props.required} 
        id={props.inputId} 
        rows={props.rows} 
      />
      
    </section>
  )
}

export default TextArea