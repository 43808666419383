import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import classes from "./Navbar.module.css";
import { FaBars } from "react-icons/fa";
import { BsXLg } from "react-icons/bs";
import logobg from "../../assets/SEAT-logo-new.png";

const Navbar = () => {
  const [shouldFade, setShouldFade] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  let resizeWindow = () => {
    if (window.innerWidth > 780) {
      setIsMobile(false);
    }
  };
  const handleScroll = () => {
    // Check the scroll position and update the state accordingly
    setShouldFade(window.scrollY > 400);
  };

  // useEffect(() => {
  //   resizeWindow();
  //   window.addEventListener("resize", resizeWindow);
  //   return () => window.removeEventListener("resize", resizeWindow);
  // }, []);

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", resizeWindow);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={`${classes.navlinks_holder} ${
          shouldFade ? classes.nav_faded : ""
        }`}
      >
        <nav className={classes.navbar}>
          <NavLink to="/" className={classes.logo}>
            <img src={logobg} alt="logo" />
          </NavLink>

          <ul
            onClick={() => setIsMobile(false)}
            className={isMobile ? classes.navlinks_mobile : classes.navlinks}
          >
            <li>
              <NavLink
                end={true}
                className={({ isActive }) =>
                  isActive ? classes.link_active : classes.link_inactive
                }
                to="/"
              >
                <h4>HOME</h4>
              </NavLink>
            </li>

            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? classes.link_active : classes.link_inactive
                }
                to="/about"
              >
                <h4>ABOUT US</h4>
              </NavLink>
            </li>

            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? classes.link_active : classes.link_inactive
                }
                to="/contact"
              >
                <h4>CONTACT</h4>
              </NavLink>
            </li>

            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? classes.link_active : classes.link_inactive
                }
                to="/services"
              >
                <h4>SERVICES</h4>
              </NavLink>
            </li>

            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? classes.link_active : classes.link_inactive
                }
                to="/assessments"
              >
                <h4>ASSESSMENTS</h4>
              </NavLink>
            </li>

            {/* <li><NavLink className={({ isActive }) => (isActive ? classes.link_active : classes.link_inactive)} to="/scheduling"><h4>SCHEDULING</h4></NavLink></li> */}
          </ul>

          <button
            onClick={() => setIsMobile(!isMobile)}
            className={classes.mobile_menu}
          >
            {isMobile ? (
              <BsXLg />
            ) : (
              // <i className="fas fa-times"></i>
              <FaBars />
            )}
          </button>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
//className={({ isActive }) => (isActive ? classes.link_active : classes.link_inactive)}
