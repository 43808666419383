import React, { useEffect, useState } from "react";
import classes from "./Assessments.module.css";
import { Unity, useUnityContext } from "react-unity-webgl";
import SPButton from "../UI/SPButton";

const Assessments = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const mobileKeywords = [
      "Mobile",
      "iPhone",
      "iPad",
      "Android",
      "Windows Phone",
    ];

    const checkIsMobile = () => {
      setIsMobile(
        mobileKeywords.some((keyword) => userAgent.includes(keyword))
      );
    };

    // Initial check
    checkIsMobile();

    // Clean up any event listeners or resources if needed
  }, []);

  const { unityProvider, isLoaded, loadingProgression, requestFullscreen } =
    useUnityContext({
      loaderUrl: "build/Builds.loader.js",
      dataUrl: "build/Builds.data",
      frameworkUrl: "build/Builds.framework.js",
      codeUrl: "build/Builds.wasm",
      productName: "SeatPsychology",
      productVersion: "1.0.0",
      companyName: "SeatPsychology",
    });

  function GoFullscreen() {
    requestFullscreen(true);
  }

  return (
    <>
      <h1 className={classes.assess_title}>Assessments</h1>

      <div className={classes.game_container}>
        <p>
          All assessments are used to help identify and diagnose patterns of a
          client. These assessments alone do not give medicial or psychological
          diagnosis. However, they are very beneficial. All recordings of the
          assessments are considered confidential.
        </p>

        <section>
          <div className={classes.game_title}>
            <h1>Streamlined Assessment For Attention (SAFA)</h1>
            {!isMobile && (
              <SPButton onClick={GoFullscreen}>Enter Fullscreen</SPButton>
            )}
          </div>
          <p>
            The following assessment helps identify attention deficits. The
            assessment gives data in understanding struggles in tasks of
            continuous attention, sustained attention, inattention, and
            implusivity.
          </p>
          {isMobile && (
            <p>
              <b>This assessment is not available for mobile devices.</b>
            </p>
          )}

          {!isMobile && isLoaded === false && (
            // We'll conditionally render the loading overlay if the Unity
            // Application is not loaded.
            <div className={classes.loading_overlay}>
              <p>Loading... ({loadingProgression}%)</p>
            </div>
          )}

          {!isMobile && (
            <Unity className={classes.game} unityProvider={unityProvider} />
          )}
        </section>
      </div>
    </>
  );
};

export default Assessments;
