// import homeimage from "../assets/bg-header.jpg"
// import Header from "../components/Header";
import InputField from "../components/InputField";
import TextArea from "../components/TextArea";
import classes from "./Contact.module.css";
import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import Modal from "../UI/Modal";

const Contact = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const form = useRef();

  const emailJSServiceToken = "service_xqho54n";
  const emailJSTemplateToken = "template_gjm4rop";
  const emailJSPublicKey = "OVx4GpGIDSK_E_RAP";

  let modalTitle = "Thank you!";
  let modalDesc = "Please allow 48 hours for us to get back to you.";
  const handleFormSubmit = (event) => {
    event.preventDefault();
    emailjs
      .sendForm(
        emailJSServiceToken,
        emailJSTemplateToken,
        form.current,
        emailJSPublicKey
      )
      .then(
        (result) => {
          // console.log(result.text);
          setModalOpen(true);
          document.getElementById("main_form").reset();
        },
        (error) => {
          modalTitle = "Error";
          modalDesc = error.text;
          // console.log(error.text);
        }
      );
  };

  return (
    <>
      {/* <Header imagebg={homeimage} className={classes.contact_header}/> */}
      <Modal
        description={modalDesc}
        title={modalTitle}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      ></Modal>
      <main className={classes.contact_main}>
        <section className={classes.title}>
          <h1>Contact Us</h1>
          <p>
            Got a question? We'd love to get in contact with you. Send us a
            message and we'll resopond as soon as possible
          </p>
        </section>
        <form ref={form} onSubmit={handleFormSubmit} id="main_form">
          <InputField
            title="Name"
            name="from_name"
            type="text"
            inputId="name"
            required={true}
          />

          <InputField
            title="Email Address"
            name="email_address"
            type="email"
            inputId="email"
            required={true}
          />

          <InputField
            title="Phone Number"
            name="number"
            type="tel"
            inputId="number"
            required={true}
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            placeholder="916-555-5555"
          />
          <InputField
            title="Best Time to Contact?"
            name="best_to_call"
            type="text"
            inputId="best_to_call"
            required={true}
          />

          <section className={classes.dropdown}>
            <label htmlFor="for_who">
              Are you looking into an assessment for:<font color="red">*</font>
            </label>

            <select required name="assessment_for" id="for_who">
              <option style={{ display: "none" }} value=""></option>
              <option value="Yourself">Yourself</option>
              <option value="Child">Your child</option>
              <option value="Other">Other</option>
            </select>
          </section>

          <InputField
            min={0}
            max={100}
            title="How old is your student/child (or you if you are an adult looking into an assessment for yourself)?"
            name="age"
            type="number"
            inputId="my_age"
            required={false}
          />

          <TextArea
            inputId="explain"
            type="text"
            title="Briefly explain your learning concerns"
            name="message"
            rows={10}
            required={true}
          />

          <TextArea
            inputId="school_district"
            type="text"
            title="What school and district does your child currently attend? (If attending a private school, please also note what your child's home school of attendance would be, i.e., your neighborhood school of attendance)"
            rows={10}
            required={false}
            name="school_district"
          />

          <section className={classes.dropdown}>
            <label htmlFor="assessed_before">
              Has your child (or you if adult referring) been assessed before?
            </label>
            <select name="assessed_before" id="assessed_before">
              <option style={{ display: "none" }} value="none"></option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </section>

          <TextArea
            inputId="prev_assessment"
            title="What was the outcome of previous assessment(s)? Does your child have a current 504 or IEP?"
            rows={10}
            required={false}
            name="prev_assessment"
          />

          <TextArea
            name="gain_from_assessment"
            inputId="gain_from_assessment"
            title="What are you hoping to gain from this assessment?"
            rows={10}
            required={false}
          />

          <button type="submit" className={classes.submit}>
            Submit
          </button>
        </form>
      </main>
    </>
  );
};

export default Contact;
