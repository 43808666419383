import classes from './Header.module.css'

const Header = (props) => {

  const inner_styl = classes.inner_content + ' ' + props.inner 
  const main_styl = classes['main-image'] + ' ' + props.className 
  
  return <>
    <div className={main_styl} style={{ 
        backgroundImage: `url(${props.imagebg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}>
      
      <div className={inner_styl}>
        {props.children}
      </div>
      {/* <img src={props.imagebg} alt="headerimage"/> */}
      
    </div>
  </>
}

export default Header;