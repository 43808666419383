import React from 'react'
import classes from './FeeInfo.module.css'
const FeeInfo = (props) => {
  return (
    <section className={classes.main}>
      
      <h3>{props.title}</h3>
      {props.children}
      
    </section>
  )
}

export default FeeInfo