import React from "react";
import classes from "./InputField.module.css";

const InputField = (props) => {
  let title = props.title;
  if (props.required) {
    title = (
      <span>
        {props.title}
        <span style={{ color: "red" }}>*</span>
      </span>
    );
  }

  return (
    <section className={classes.input_f}>
      <label htmlFor={props.inputId}>{title}</label>
      <input
        type={props.type}
        min={props.min}
        max={props.max}
        id={props.inputId}
        name={props.name}
        required={props.required}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
        pattern={props.pattern}
      />
    </section>
  );
};

export default InputField;
