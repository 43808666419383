// import Header from "../components/Header";
// import homeimage from "../assets/bg-header.jpg"
import classes from "./Services.module.css";

import FeeInfo from "../components/FeeInfo";

const init_consultation_desc =
  "There is no charge for a 15 minute initial phone or email consultation.  In order to best determine if Bryon is able to address your child’s needs, and if he is the right professional to work with, you will complete a brief intake form (under the Contact tab) prior to the consultation in order to effectively address your concerns.";

const iq_tite = "IQ TESTING";
const iq_desc =
  "Intelligence Testing only (used primarily for Gifted and Talented Education GATE or MENSA) is $750.";

const psychoed_assessment_title = "PSYCHOEDUCATIONAL ASSESSMENTS";
const psychoed_assessment_desc =
  "Assessment fees are calculated based on your individual referral concerns and the depth of the assessment needed to adequately address the learning concerns. The scope of a comprehensive psychoeducational assessment is highly individualized; upon consultation regarding the learning concerns and the areas needed to be assessed, you will be provided the cost of the complete assessment.";

const consultation_title = "CONSULTATION";
const consultation_desc =
  "Bryon is also available to consult on individual cases as needed.  Many parents already have completed assessments and IEP’s, and simply seek services for consultation to review existing information and provide recommendations or suggested follow up steps on the basis of current information from an experienced resource outside of their school system.  Very often, simply obtaining a “second opinion” from a trusted outside source is helpful.  Consultation fees are charged at the hourly rate of $150 with a 2 hour minimum and are paid at the consultation appointment.";

const fee_payable_title = "FEES PAYABLE";
const fee_payable_desc =
  "For diagnostic psychoeducational assessments, half of the total fee is payable at the beginning of the initial test session, with the other half of the balance paid at the second test session. For intelligence testing, the total fee is payable at the testing appointment. Methods of payment include cash, check, or credit card.";

const insurance_title = "INSURANCE";
const insurance_desc =
  'Insurance payment is not currently accepted.  However, insurance often does cover costs for psychoeducational assessment.  It is extremely important for clients to check about the process with their provider before getting started to make sure!  For clients wishing to work with their insurance company, the testing process proceeds as usual with full payment required at the assessment appointments.  Clients will then be provided a "superbill" which contains all of the information that the insurance company needs, and you then submit the superbill to your insurance provider to request that they reimburse you. ';

const Services = () => {
  return (
    <>
      {/* <Header imagebg={homeimage} className={classes.service_header} /> */}
      <main>
        <div className={classes.services_container}>
          <h1>INITIAL CONSULTATION</h1>
          <p>{init_consultation_desc}</p>
          <div className={classes.fees_title_div}>
            <h1>FEES FOR ASSESSMENTS</h1>
            <h2>
              There is a sliding scale. Fees are Negotiable for all assessments.
            </h2>
          </div>

          <div className={classes.fees_assessments}>
            <FeeInfo title={iq_tite}>
              <p>{iq_desc}</p>
            </FeeInfo>

            <FeeInfo title={psychoed_assessment_title}>
              <p>{psychoed_assessment_desc}</p>
            </FeeInfo>

            <FeeInfo title={consultation_title}>
              <p>{consultation_desc}</p>
            </FeeInfo>

            <FeeInfo title={fee_payable_title}>
              <p>{fee_payable_desc}</p>
            </FeeInfo>

            <FeeInfo title={insurance_title}>
              <p>{insurance_desc}</p>
            </FeeInfo>
          </div>

          {/* <LineBreak size={"medium"}/> */}
          {/* <ServiceInfo image={homeimage} title={title} desc={desc}/>
          <ServiceInfo image={homeimage} title={title} desc={desc}/>
          <ServiceInfo image={homeimage} title={title} desc={desc}/>
          <ServiceInfo image={homeimage} title={title} desc={desc}/>
          <ServiceInfo image={homeimage} title={title} desc={desc}/> */}
        </div>
      </main>
    </>
  );
};

export default Services;
