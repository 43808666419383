import React from 'react'
import classes from './ImageInfo.module.css'
import { NavLink } from "react-router-dom";
const ImageInfo = (props) => {
  return (
    <>
      <div className={classes.image_container}>
        <img src={props.image} alt="imageinfologo" className={classes.image}/>
      </div>
      <NavLink to={props.to} className={classes.link}><h1 >{props.title}</h1></NavLink>
      <p className={classes.desc}>{props.desc}</p>
      
    </>
  )
}

export default ImageInfo