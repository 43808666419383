
import classes from './App.module.css';
import { Route, Routes } from "react-router-dom"
import Home from './Routes/Home';
import About from './Routes/About';
import Contact from './Routes/Contact';
import Scheduling from './Routes/Scheduling';
import Services from './Routes/Services';
import Testimonials from './Routes/Testimonials';
import NavBar from './components/Navigation/Navbar';
import Assessments from './Routes/Assessments';
import Footer from './components/Footer';
import ScrollToTop from './ScrollToTop';

function App() {

  return (
    <>
      <div className={classes.app}>
        <ScrollToTop />
        <NavBar />


        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/scheduling" element={<Scheduling />} />
          <Route path="/services" element={<Services />} />
          <Route path="/assessments" element={<Assessments />} />
          <Route path="/testimonials" element={<Testimonials />} />
        </Routes>
        <Footer />
      </div>

    </>
  );
}

export default App;
