import React from 'react'
import classes from './LineBreak.module.css'
const LineBreak = (props) => {



  return (
    <span className={classes[props.size]}></span>
  )
}

export default LineBreak