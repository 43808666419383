// import Header from "../components/Header";
// import homeimage from "../assets/bg-header.jpg"
const Testimonials = () => {
  return (
    <>
      {/* <Header imagebg={homeimage}/> */}
      <div>
        testimonials page.
      </div>
    </>
  )
}

export default Testimonials;