import React from 'react'
import classes from './Modal.module.css'
import  ReactDOM  from 'react-dom'
const Modal = (props) => {
  if(!props.isOpen) return null;
  return ReactDOM.createPortal(
    <>
      <div onClick={props.onClose} className={classes.modal_backdrop}/>
      <div  className={classes.modal_main} >
        <div className={classes.modal_content}>
          <h2>{props.title}</h2>
          <p>{props.description}</p>
          <button onClick={props.onClose} >Close</button>
        </div>
        
      </div>
    </>,
    document.getElementById('portal')
  )
}

export default Modal