import React from "react";
import classes from "./Footer.module.css";
import fbLogo from "../assets/fb.svg";
import linkedinLogo from "../assets/linkedin.svg";

const Footer = () => {
  return (
    <footer className={classes.footer_main}>
      <div>
        <img src={fbLogo} alt="logo" />
        <a
          href="https://www.linkedin.com/in/bryon-vignerie-31b16072/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedinLogo} alt="logo" />
        </a>
      </div>
      <div className={classes.contact_info}>
        <h1>Bryon Vignerie</h1>
        <h4>Licensed Educational Psychologist</h4>
        <h4>M.A., LEP 4133, ABSNP</h4>
        <h4>Office: 562 637-6379</h4>
      </div>
    </footer>
  );
};

export default Footer;
