import Header from "../components/Header";
import homeimage from "../assets/bg-header.jpg";
import classes from "./Home.module.css";
import InfoCard from "../UI/InfoCard";
// import logo1 from "../assets/life-3.svg";
import sLogo from "../assets/s.png";
import eLogo from "../assets/e.png";
import aLogo from "../assets/a.png";
import tLogo from "../assets/t.png";
import { NavLink } from "react-router-dom";
import ImageInfo from "../UI/ImageInfo";
import seatlogo from "../assets/SEAT-logo-new.png";
const Home = () => {
  const spec_desc =
    "We Concentrate and Fine Tune Assessments to Your Specific Needs.";
  const educ_desc =
    "We Provide Coaching and Teaching Geared Towards Your Learning Style";

  const assess_desc =
    "We Provide Thorough and Precise Assessments Towards Your Learning Strengths and Challenges.";
  const treatment_desc =
    "Our Services are Customized Toward Helping Students Maximize Their Potential and Improving Their Performance.";

  const trust_title = "Trust a professional with experience";

  const trust_text =
    "Bryon has over twelve years of experience conducting Psycho-Educational Evaluations. He maintains a certification through the American Board of School-Neuropsychology, and is eager about the sciences of learning. He loves using his expertise to help individuals better understand their learning strengths and challenges.";

  const imageInfoDesc =
    "Learn more about Bryon, our services, and how our approach to educational psychology sets us apart.";

  return (
    <div>
      <Header imagebg={homeimage}>
        <div className={classes.header_content_holder}>
          {/* <h3><i>Success</i></h3> */}
          <h1>TAKE A S.E.A.T.</h1>
        </div>
      </Header>
      <main>
        <div className={classes.info2}>
          <InfoCard
            title="SPECIALIZATION"
            description={spec_desc}
            logo={sLogo}
          />
          <InfoCard title="EDUCATION" description={educ_desc} logo={eLogo} />
          <InfoCard title="ASSESSMENT" description={assess_desc} logo={aLogo} />
          <InfoCard
            title="TREATMENT"
            description={treatment_desc}
            logo={tLogo}
          />
        </div>

        <div className={classes.line}>
          <span></span>
        </div>

        <div className={classes.container}>
          <div className={classes.trust}>
            <div className={classes.trust_content}>
              <h1>{trust_title}</h1>
              <p className={classes.trust_desc}>{trust_text}</p>
              <NavLink to="/services" className={classes.services_link}>
                <h3>See Our Services</h3>
              </NavLink>
            </div>
          </div>

          <div className={classes.imageinfo}>
            <ImageInfo
              desc={imageInfoDesc}
              title="ABOUT"
              image={seatlogo}
              to="/about"
              linktitle="Learn More"
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;
