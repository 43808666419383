import React from 'react'
import classes from './SPButton.module.css'

const SPButton = (props) => {

  const styl = classes.spButton + ' ' + props.className

  return (

    <button className={styl} onClick={props.onClick} >
      {props.children}
    </button>

  )
}

export default SPButton